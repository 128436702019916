import { v4 as uuid } from 'uuid';

window.addEventListener('DOMContentLoaded', () => {
  // tracking when the whole card is clickable
  const cards = document.querySelectorAll('.js-card-link');

  // tracking when only the button on the card is clickable
  const cardButtons = document.querySelectorAll('.js-card-button');

  if (cards) {
    cards.forEach((card) => {
      card.addEventListener('click', (e) => {
        const element = e.currentTarget;
        const title = element.querySelector('h3');

        window.tagular('beam', 'ElementClicked', {
          '@type': 'redventures.usertracking.v3.ElementClicked',
          webElement: {
            elementType: 'CARD',
            location: 'CONTENT CARDS',
            position: 'SECONDARY',
            text: title.textContent.toUpperCase(),
          },
          correlationId: uuid(),
          outboundUrl: element.href,
          actionOutcome: 'INTERNALLINK',
        });
      });
    });
  }

  if (cardButtons) {
    cardButtons.forEach((button) => {
      button.addEventListener('click', (e) => {
        const element = e.currentTarget;

        window.tagular('beam', 'ElementClicked', {
          '@type': 'redventures.usertracking.v3.ElementClicked',
          webElement: {
            elementType: 'CARD',
            location: 'CONTENT CARDS',
            position: 'SECONDARY',
            text: element.textContent.toUpperCase(),
          },
          correlationId: uuid(),
          outboundUrl: element.href,
          actionOutcome: 'INTERNALLINK',
        });
      });
    });
  }
});
